import { useEffect, useState } from "react";
import axios from "axios";

import Toggle from "react-toggle";
import "react-toggle/style.css";

import logo from "../img/bay-smokes/route-logo.webp";

const fetchRouteProduct = async () => {
  try {
    const { data } = await axios.get(
      `https://bay-smokes.myshopify.com/products/routeins.json`
    );

    return data?.product;
  } catch (error) {
    console.error("Error fetching Route product:", error);
  }
};

const getRoute = async (cart, subtotal) => {
  const cartRef = cart.id;
  const merchantId = "merch_75aNW4b3woAjQlpwCrpq";
  const currency = "USD";

  const cartItems = cart.lines.edges
    .filter(({ node }) =>
      node.merchandise.product.title.toLowerCase().includes("route")
    )
    .map(({ node }) => ({
      id: node.merchandise.id, //.replace("gid://shopify/ProductVariant/", ""),
      unit_price: {
        currency: "USD",
        amount: node.merchandise.price.amount,
      },
      quantity: node.quantity,
    }));

  try {
    const { data: quote } = await axios.post(
      "https://api.route.com/v2/quotes",
      {
        merchant_id: merchantId,
        cart: {
          cart_ref: cartRef, //.replace("gid://shopify/Cart/", ""),
          covered: {
            currency: currency,
            amount: subtotal,
          },
          cart_items: cartItems,
        },
      },
      { headers: { Secret: "3db79323-c4e5-4b87-b7fb-e60d596a1472" } }
    );

    const product = await fetchRouteProduct();
    const matchedVariant = matchVariant(product, quote.premium.amount);

    if (matchedVariant) {
      return {
        product,
        productID: `gid://shopify/Product/${product.id}`,
        variantID: `gid://shopify/ProductVariant/${matchedVariant.id}`,
        price: matchedVariant.price,
      };
    } else {
      throw new Error("No matching variant found");
    }
  } catch (error) {
    console.error("Error fetching quote:", error);
  }
};

const matchVariant = (product, premiumAmount) => {
  const sortedVariants = product.variants.sort(
    (a, b) => parseFloat(a.price) - parseFloat(b.price)
  );

  if (premiumAmount > 0)
    return (
      sortedVariants.find(
        (variant) => parseFloat(variant.price) >= premiumAmount
      ) || sortedVariants[sortedVariants.length - 1]
    );
};

const calcSubtotal = (cart) =>
  cart?.lines?.edges
    ?.reduce(
      (acc, { node: item }) =>
        item.merchandise.product.title.toLowerCase().includes("route")
          ? acc
          : acc + Number(item.merchandise.price.amount) * item.quantity,
      0
    )
    ?.toFixed(2);

export const Route = ({ cart, actions }) => {
  const [quote, setQuote] = useState();
  const [currentVariant, setCurrentVariant] = useState();
  const [loading, setLoading] = useState(false);

  console.log(cart);

  useEffect(() => {
    const initRoute = async () => {
      if (!cart?.lines?.edges?.length > 0) return;

      const subtotal = calcSubtotal(cart);
      const res = await getRoute(cart, subtotal);

      if (res?.variantID) {
        actions.addProtection({
          productID: res?.productID,
          variantID: res?.variantID,
        });
        setCurrentVariant(res.variantID);
        setQuote(res);
      }
    };

    initRoute();
  }, []);

  useEffect(() => {
    const updateProtection = async () => {
      if (!currentVariant || !cart?.id || !cart?.lines?.edges?.length > 0)
        return;

      const subtotal = calcSubtotal(cart);
      const res = await getRoute(cart, subtotal);

      if (currentVariant !== res?.variantID) {
        await actions.removeProtection({ variantID: currentVariant });
        await actions.addProtection({
          productID: res.productID,
          variantID: res.variantID,
        });
        setCurrentVariant(res.variantID);
        setQuote(res);
      }
    };

    updateProtection();
  }, [cart, currentVariant]);

  const handleAddRoute = () => {
    if (cart?.id) {
      setLoading(true);
      actions.addProtection({
        productID: quote.productID,
        variantID: quote.variantID,
      });
      setCurrentVariant(quote.variantID);
      setLoading(false);
    }
  };

  const handleRemoveRoute = () => {
    setLoading(true);
    actions.removeProtection({ variantID: currentVariant });
    setCurrentVariant(null);
    setLoading(false);
  };

  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-narrow">
          <div className="image is-32x32">
            <img src={logo} alt="Route logo" />
          </div>
        </div>
        <div className="column">
          <h5 className="is-size-6 has-text-weight-bold">Package Protection</h5>
          <p className="is-size-7">
            Protect against loss, theft, or damage in transit. Instant
            resolution.
          </p>
          <span className="tag is-rounded mt-1">
            100% Carbon Neutral Shipping
          </span>
        </div>
        <div className="column is-narrow">
          <div className="field is-grouped is-grouped-multiline">
            {quote?.price && (
              <label htmlFor="protection-status" className="mr-1">
                ${quote?.price}
              </label>
            )}
            <Toggle
              id="protection-status"
              checked={currentVariant}
              onChange={currentVariant ? handleRemoveRoute : handleAddRoute}
            />
          </div>
        </div>
      </div>
    </>
  );
};
